$(() => {
  const url = encodeURIComponent(document.URL);
  const settings = 'menubar=no,toolbar=no,status=no,width=670,height=570';
  const title = $('meta[property="og:title"]').attr('content');

  $('.share-link.twitter').on('click', (e) => {
    e.preventDefault();
    const link = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;
    window.open(link, 'Sharing', settings);
  });

  $('.share-link.facebook').on('click', (e) => {
    e.preventDefault();
    const link = `https://www.facebook.com/sharer.php?u=${url}`;
    window.open(link, 'Sharing', settings);
  });
});
