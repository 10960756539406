window.addEventListener('DOMContentLoaded', () => {
  const images = document.querySelectorAll('.img-to-svg');

  images.forEach((image) => {
    fetch(image.src)
      .then((response) => response.text())
      .then((data) => {
        const parser = new DOMParser();
        const svg = parser.parseFromString(data, 'image/svg+xml').querySelector('svg');

        if (image.id) svg.id = image.id;
        if (image.className) svg.classList = image.classList;

        image.parentNode.replaceChild(svg, image);
      })
      .catch(console.error);
  });
});
