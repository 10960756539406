import '../scss/main.scss';
import App from './app';

import './behaviors/status';
import './behaviors/input-time-picker';
import './behaviors/input-rut';
import './behaviors/carousel';
import './behaviors/ugly-ellipsis';
import './behaviors/banner';
import './behaviors/navbar';
import './behaviors/img-to-svg';
import './behaviors/social-share';

import './plugins/select2';

$(() => {
  $('.alert').each((i, item) => {
    App.utils.highlight($(item));
  });

  const $alert = $('.main-alert .alert');
  setTimeout(() => $alert.fadeOut(), 10000);

  $('.model-form input:text').addClass('form-control');

  $('form').submit((e) => {
    const $this = $(e.currentTarget);
    const $buttons = $this.find(':submit').not('.js-do-not-disable-on-submit');

    // disable buttons after submit to prevent disabling submit inputs
    // with values
    setTimeout(() => {
      $buttons.addClass('disabled').prop('disabled', true);
      App.utils.showLoading($buttons);

      setTimeout(() => {
        $buttons.removeClass('disabled').prop('disabled', false);
        App.utils.hideLoading();
      }, 3000);
    }, 10);

    return true;
  });

  const $html = $('html');

  if (
    !$html.hasClass('a11y-font-0')
    || !$html.hasClass('a11y-font-1')
    || !$html.hasClass('a11y-font-2')
  ) {
    $('.toolbar-behavior-increase').click();
  }
});
