$.fn.select2.amd.require(
  ['select2/utils', 'select2/selection/single', 'select2/selection/allowClear'],
  (Utils, SingleSelection, AllowClear) => {
    // eslint-disable-next-line no-param-reassign,func-names
    AllowClear.prototype.update = function (decorated, data) {
      decorated.call(this, data);

      if (this.$selection.find('.select2-selection__placeholder').length > 0
        || data.length === 0) {
        return;
      }

      const removeAll = this.options.get('translations').get('removeAllItems');
      const $remove = $(
        `<span 
          class="select2-selection__clear" 
          title="${removeAll()}">
          <em class="cl cl-close"/>
        </span>`
      );
      Utils.StoreData($remove[0], 'data', data);

      this.$selection.find('.select2-selection__rendered').prepend($remove);
    };

    return Utils.Decorate(SingleSelection, AllowClear);
  }
);

$(() => {
  $('select').not('.js-not-select2').each((i, select) => {
    const $select = $(select);
    $select.select2({
      width: '100%',
      theme: 'classic',
      allowClear: true
    });

    if ($select.data('value')) {
      $select.val($select.data('value'));
      $select.trigger('change');
    }
  });
});
