window.addEventListener('DOMContentLoaded', () => {
  $('.banner').each((i, banner) => {
    const $banner = $(banner);

    const count = $banner.children().length;

    $banner.slick({
      arrows: false,
      slidesToShow: 1,
      mobileFirst: true,
      prevArrow: '<em class="fas fa-chevron-left banner-left">',
      nextArrow: '<em class="fas fa-chevron-right banner-right">',
      responsive: [
        {
          breakpoint: 576,
          settings: {
            slidesToShow: count <= 2 ? count : 2,
            slidesToScroll: 1,
            arrows: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: count <= 4 ? count : 4,
            slidesToScroll: 1,
            arrows: true
          }
        }
      ]
    });
  });
});
