$(() => {
  $('.navbar').each((i, nav) => {
    const $nav = $(nav);

    $nav.on('show.bs.collapse', '.collapse-level-2', (event) => {
      event.stopImmediatePropagation();

      $nav.find('.collapse-level-2.show').collapse('hide');
    });

    $nav.on('show.bs.collapse', '.collapse-level-1', (event) => {
      event.stopImmediatePropagation();

      $nav.find('.collapse-level-1.show').collapse('hide');
    });
  });
});
