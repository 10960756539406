$(() => {
  $('.carousel').each((i, item) => {
    const $element = $(item);
    const interval = $element.data('interval') / 1000;
    const pause = $element.data('pause');
    const ride = $element.data('ride');

    const $carouselAnimationLeft = $element.find('.carousel-control-animation-left span');
    const $carouselAnimationRight = $element.find('.carousel-control-animation-right span');
    $carouselAnimationLeft[0].style.animationDuration = `${interval}s`;
    $carouselAnimationRight[0].style.animationDuration = `${interval}s`;

    if (ride !== 'carousel') {
      $carouselAnimationLeft[0].style.animation = 'none';
      $carouselAnimationRight[0].style.animation = 'none';
    }

    $element.on('slide.bs.carousel', () => {
      if (ride !== 'carousel') {
        return;
      }

      $carouselAnimationLeft[0].style.animation = 'none';
      $carouselAnimationRight[0].style.animation = 'none';

      setTimeout(() => {
        $carouselAnimationLeft[0].style.animation = '';
        $carouselAnimationRight[0].style.animation = '';
      }, 0);
    });

    $element.on('mouseenter', () => {
      if (pause === 'hover') {
        $carouselAnimationLeft[0].style.animation = 'none';
        $carouselAnimationRight[0].style.animation = 'none';
      }
    });
  });
});
